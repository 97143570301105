import "./index.css";
import Screens from "./Screens";
import Footer from "./Components/CommonComponents/Footer";

function App() {
  return (
    <>
      <Screens />
      <Footer />
    </>
  );
}

export default App;
