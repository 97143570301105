import React from "react";

function ContactUsContent() {
  return (
    <section className="contact-us-container">
      <h1 className="section-header">CONTACT US</h1>
      <span>CALL US</span>
      <span>(763) 269 - 7133</span>
      <span>COME VISIT US</span>
      <span>3373 Coon Rapids Blvd NW, Coon Rapids, MN 55433</span>
      <span>OPEN HOURS</span>
      <div className="footer-hours">
        <div className="hours-container">
          <span>Mon - Wed:</span>
          <span>10am - 6pm</span>
        </div>
        <div className="hours-container">
          <span>Thurs - Sat:</span>
          <span>10am - 7pm</span>
        </div>
        <div className="hours-container">
          <span>Sunday:</span>
          <span>Closed</span>
        </div>
      </div>
    </section>
  );
}

export default ContactUsContent;
