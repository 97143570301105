import React from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

function Drawer(props) {
  const { closeDrawer } = props;

  const handleLogoClick = () => (window.location.href = "/");
  const handleActiveItem = ({ isActive }) => (isActive ? "selected-nav" : "");

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth > 768 && closeDrawer()
    );
    return window.removeEventListener(
      "resize",
      () => window.innerWidth > 768 && closeDrawer()
    );
  }, []);

  return (
    <div className="drawer-background">
      <div className="drawer-container">
        <img
          src="Images/misc/Harvest Market- horizontal-02.png"
          className="drawer-logo"
          onClick={handleLogoClick}
          alt="Harvest African Market Logo"
        />
        <i className="fa-solid fa-xmark drawer-x" onClick={closeDrawer}></i>
        <ul className="drawer-list">
          <li className="header-list-item" onClick={closeDrawer}>
            <NavLink to="/menu" className={handleActiveItem}>
              MENU
            </NavLink>
          </li>
          <li className="header-list-item" onClick={closeDrawer}>
            <a href="https://doordash.com" target="blank">
              ORDER ONLINE
            </a>
          </li>
          <li className="header-list-item" onClick={closeDrawer}>
            <NavLink to="/contactUs" className={handleActiveItem}>
              CONTACT US
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Drawer;
