import React from "react";
import { useState } from "react";
import MenuMobileDropdown from "./MenuMobileDropdown";

function MenuMobileNavigation(props) {
  const { activeLink, handleClick, menuCatagories } = props;
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const dropDownCatagories = menuCatagories.filter(
    (catagorie) => catagorie !== activeLink
  );
  const handleDropdown = () => setIsDropDownOpen((prevState) => !prevState);

  return (
    <div
      className="menu-navigation-container menu-mobile-navigation"
      onClick={handleDropdown}
    >
      <span className="menu-link active-menu-link">{activeLink}</span>
      <i className="fa-solid fa-ellipsis-vertical mobile-dots"></i>
      {isDropDownOpen && (
        <MenuMobileDropdown
          dropDownCatagories={dropDownCatagories}
          handleClick={handleClick}
        />
      )}
    </div>
  );
}

export default MenuMobileNavigation;
