import React from "react";

function HomeBilboard() {
  const image = "Images/entree/fufu_and_soup.jpg";
  const altText = "A bowl of rice sitting on a white marble counter top";

  return (
    <section className="home-jumbotron-container">
      <img src={image} alt={altText} />
    </section>
  );
}

export default HomeBilboard;
