import React from "react";

function GoogleMap() {
  return (
    <section className="google-map-container">
      <iframe
        title="google map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2812.405353074847!2d-93.3549807237758!3d45.1788901710708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b33c08e547f3b9%3A0x94c712ba07762d3!2sHarvest%20African%20Market!5e0!3m2!1sen!2sus!4v1685662959418!5m2!1sen!2sus"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </section>
  );
}

export default GoogleMap;
