import React from "react";

function MenuPrice(props) {
  const { description, price } = props;

  return (
    <div className="price-container">
      {!!description && <span>{description}</span>}
      <span>{price}</span>
    </div>
  );
}

export default MenuPrice;
