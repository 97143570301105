import React from "react";

function MenuMobileDropdown(props) {
  const { dropDownCatagories, handleClick } = props;
  const dropDownList = dropDownCatagories.map((catagorie) => (
    <li
      key={`mobile ${catagorie}`}
      className="menu-mobile-list-item"
      onClick={handleClick(catagorie)}
    >
      {catagorie}
    </li>
  ));

  return <ul className="menu-mobile-dropdown-container">{dropDownList}</ul>;
}

export default MenuMobileDropdown;
