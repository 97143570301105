import React from "react";
import MenuItem from "./MenuItem";
import entree from "../../MenuData/entree.json";
import soups from "../../MenuData/soups.json";
import sides from "../../MenuData/sides.json";
import addOns from "../../MenuData/addons.json";
import beverages from "../../MenuData/beverages.json";

const selectCurrentMenu = (activeLink, menuCatagories) => {
  let menuItems = [];

  switch (activeLink) {
    case menuCatagories[0]:
      menuItems = entree;
      break;
    case menuCatagories[1]:
      menuItems = soups;
      break;
    case menuCatagories[2]:
      menuItems = sides;
      break;
    case menuCatagories[3]:
      menuItems = addOns;
      break;
    case menuCatagories[4]:
      menuItems = beverages;
      break;
    default:
      menuItems = entree;
  }

  return menuItems;
};

function MenuList(props) {
  const { activeLink, menuCatagories } = props;

  const currentSelection = selectCurrentMenu(activeLink, menuCatagories) || [];

  const menuItems = currentSelection.menuItems.map((item) => (
    <MenuItem key={item.dishName} item={item} />
  ));

  return <section className="menu-list-container">{menuItems}</section>;
}

export default MenuList;
