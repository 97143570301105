import React from "react";
import { useNavigate } from "react-router-dom";

function HomeMenu() {
  const navigate = useNavigate();

  const handleClick = () => navigate("/menu");

  return (
    <section className="home-menu-container">
      <div className="home-menu-text-container">
        <h2 className="section-sub-header home-menu-header">ORDER WITH US</h2>
        <p className="home-menu-paragraph">
          Harvest African Market is a family owned grocery store and restaurant located in Coon Rapids Minnesota. At Harvest African Market,
          you will discover authentic African ingredients, spices, and other staples. We also serve mouthwatering 
          dishes that will be sure to satisfy any craving.
        </p>
        <button className="tertiary-button" onClick={handleClick}>
          MENU
        </button>
      </div>
      <div className="home-menu-image-container">
        <img
          src="Images/misc/Harvest Store.jpg"
          alt="Image of Harvest African Market storefront"
        />
      </div>
    </section>
  );
}

export default HomeMenu;
