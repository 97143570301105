import React from "react";
import MenuPrice from "./MenuPrice";

function MenuItem(props) {
  const { item } = props;
  const { description, dishName, price, substitutions, imageURL } = item;

  const subs = substitutions.map((sub) => {
    const { addition, portion, size, price } = sub;
    const hasAddition = !!addition;
    const hasPortion = !!portion;
    const description = hasAddition
      ? `Subsitution ${addition}`
      : hasPortion
      ? `${portion} Portion`
      : size;

    return (
      <MenuPrice key={sub.addition} description={description} price={price} />
    );
  });

  console.log(imageURL);

  return (
    <div className="menu-item-container">
      <h2 className="section-sub-header menu-item-header">
        {dishName.toUpperCase()}
      </h2>
      <div className="menu-item-body-container">
        <div className="menu-item-text-container">
          <p className="menu-item-paragraph">{description}</p>
          {price && (
            <MenuPrice
              key="regular portion"
              description="Regular Portion"
              price={price}
            />
          )}
          {subs}
        </div>
        <div className="menu-item-image-container">
          {!!imageURL && (
            <img
              src={process.env.PUBLIC_URL + `/Images/${imageURL}`}
              alt={dishName}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MenuItem;
