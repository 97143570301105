import React from "react";
import { useState } from "react";
import MenuNavigation from "./MenuNavigation";
import MenuMobileNavigation from "./MenuMobileNavigation";
import MenuList from "./MenuList";

const MENU_CATAGORIES = ["ENTREE", "SOUPS", "SIDES", "ADD ONS", "BEVERAGES"];

function MenuContent() {
  const [activeLink, setActiveLink] = useState(MENU_CATAGORIES[0]);

  const handleClick = (link) => {
    const onClick = () => setActiveLink(link);
    return onClick;
  };

  return (
    <section>
      <MenuNavigation
        activeLink={activeLink}
        handleClick={handleClick}
        menuCatagories={MENU_CATAGORIES}
      />
      <MenuMobileNavigation
        activeLink={activeLink}
        handleClick={handleClick}
        menuCatagories={MENU_CATAGORIES}
      />
      <MenuList activeLink={activeLink} menuCatagories={MENU_CATAGORIES} />
    </section>
  );
}

export default MenuContent;
