import React from "react";
import { NavLink } from "react-router-dom";

function Header(props) {
  const { openDrawer } = props;
  const handleActiveItem = ({ isActive }) => (isActive ? "selected-nav" : "");
  const handleLogoClick = () => (window.location.href = "/");

  const doordashLink =
    "https://www.doordash.com/store/harvest-african-market-minneapolis-454424/?gclid=CjwKCAjwvdajBhBEEiwAeMh1U_snTRRpzvg4IgzbpWWiFwGk9rru8ZyU8F1kn2pMCzK-JDv6txb5WhoC6jMQAvD_BwE&gclsrc=aw.ds&ignore_splash_experience=true&kclickid=_k_CjwKCAjwvdajBhBEEiwAeMh1U_snTRRpzvg4IgzbpWWiFwGk9rru8ZyU8F1kn2pMCzK-JDv6txb5WhoC6jMQAvD_BwE_k_&utm_adgroup_id=119593491520&utm_campaign=CX_US_SE_SU_GO_ACQ_RMWXXX_12191811269_RSTRNT_%20NB_ACQ_RESTR_DSARestaurant_EVG_CPAx_XXX_Y0391_EN_EN_X_DOOR_GO_SE_DSA_MIDWESTXXX&utm_content=119593491520&utm_creative_id=591223706293&utm_keyword_id=dsa-796078878387&utm_medium=SEMu&utm_source=Google&utm_term=";

  return (
    <div className="header-container">
      <img
        src="Images/misc/Harvest Market- horizontal-02.png"
        className="header-logo"
        onClick={handleLogoClick}
        alt="Harvest African Market Logo"
      />
      <nav>
        <ul className="header-list">
          <li className="header-list-item">
            <NavLink to="/menu" className={handleActiveItem}>
              MENU
            </NavLink>
          </li>
          <li className="header-list-item">
            <a href={doordashLink} target="blank">
              ORDER ONLINE
            </a>
          </li>
          <li className="header-list-item">
            <NavLink to="/contactUs" className={handleActiveItem}>
              CONTACT US
            </NavLink>
          </li>
        </ul>
        <i className="fa-solid fa-bars hamburger-nav" onClick={openDrawer}></i>
      </nav>
    </div>
  );
}

export default Header;
