import React from "react";
import HomeMenu from "../Components/HomeComponents/HomeMenu";
import HomeOrder from "../Components/HomeComponents/HomeOrder";
import HomeBilboard from "../Components/HomeComponents/HomeBilboard";
import HomeContact from "../Components/HomeComponents/HomeContact";
import useScrollToTop from "../Utils/useScrollToTop";

function Home() {
  useScrollToTop();

  return (
    <main>
      <HomeBilboard />
      <HomeMenu />
      <HomeContact />
      <HomeOrder />
    </main>
  );
}

export default Home;
