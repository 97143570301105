import React from "react";

function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-store-info-container">
        <div className="footer-info-container">
         <h4 className="footer-section-header">ADRESS</h4>
          <span className="footer-text">
            3373 Coon Rapids Blvd NW,<br /> Coon Rapids, MN 55433
          </span>
        </div>
        <div className="footer-info-container">
          <h4 className="footer-section-header footer-hours">OPEN HOURS</h4>
          <div className="hours-container">
            <span>Mon - Wed:</span>
            <span>10am - 6pm</span>
          </div>
          <div className="hours-container">
            <span>Thurs - Sat:</span>
            <span>10am - 7pm</span>
          </div>
          <div className="hours-container">
            <span>Sunday:</span>
            <span>Closed</span>
          </div>
        </div>
        <div className="footer-info-container">
          <h4 className="footer-section-header">CONTACT US</h4>
          <span>(763) 269 - 7133</span>
        </div>
      </div>

      <div className="footer-logo-container">
        <div className="footer-info-container">
        <img
          src="Images/misc/Money Gram Vector.png"
          className="footer-logo"
          alt="Money Gram Logo"
        />
        </div>
        <div className="footer-info-container">
        <img
          src="Images/misc/nobel_one.png"
          className="footer-logo"
          alt="Nobel One Logo"
        />
        </div>
        <div className="footer-info-container">
        <img
          src="Images/misc/Ria Logo.jpeg"
          className="footer-logo"
          alt="Ria Logo"
        />
        </div>
        <div className="footer-info-container">
        <img
          src="Images/misc/Supplemental_Nutrition_Assistance_Program_logo.svg_.png"
          className="footer-logo"
          alt="Supplemental Nutrition Assistance Program Logo"
        />
        </div>
      </div>
    
    </footer>
  );
}

export default Footer;
