import React from "react";

function ContactUsJumbotron() {
  return (
    <section className="menu-jumbotron-container">
      <img
        src="Images/entree/fufu_and_soup.jpg"
        alt="Round platter holding a bowl of Liberian fufu and soup"
      />
    </section>
  );
}

export default ContactUsJumbotron;
