import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from "react";
import Header from "./Components/CommonComponents/Header";
import Home from "./Screens/Home";
import Menu from "./Screens/Menu";
import ContactUs from "./Screens/ContactUs";
import Drawer from "./Components/CommonComponents/Drawer";

function Screens() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);

  return (
    <BrowserRouter>
      <Header openDrawer={openDrawer} />
      {drawerOpen && (
        <Drawer
          closeDrawer={closeDrawer}
          openingAnimation={drawerOpen.openingAnimation}
          closingAnimation={drawerOpen.closingAnimation}
        />
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/contactUs" element={<ContactUs />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Screens;
