import React from "react";

function HomeContact() {
  return (
    <section className="home-contact-container">
      <h2 className="section-sub-header home-order-header">
        CALL TO PLACE YOUR ORDER
      </h2>
      <h3 className="section-header">(763) 269 - 7133</h3>
    </section>
  );
}

export default HomeContact;
