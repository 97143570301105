import React from "react";
import ContactUsContent from "../Components/ContactUsComponents/ContactUsContent";
import GoogleMap from "../Components/ContactUsComponents/GoogleMap";
import useScrollToTop from "../Utils/useScrollToTop";

function ContactUs() {
  useScrollToTop();
  return (
    <main>
      <ContactUsContent />
      <GoogleMap />
    </main>
  );
}

export default ContactUs;
