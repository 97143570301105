import React from "react";

function HomeOrder() {
  return (
    <section className="home-order-container">
      <img
        className="home-order-image"
        src="Images/misc/Harvest_African_Market-22.jpg"
        alt="Image of six white bowls filled with traditional Liberian dishes"
      />
      <div className="home-order-text-container">
        <h2 className="section-sub-header home-order-header">
          CALL TO PLACE YOUR ORDER
        </h2>
        <h3 className="section-header">(763) 269 - 7133</h3>
      </div>
    </section>
  );
}

export default HomeOrder;
