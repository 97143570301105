import React from "react";

function MenuNavigation(props) {
  const { activeLink, handleClick, menuCatagories } = props;

  const menuLinks = menuCatagories.map((link) => {
    const active = link === activeLink;
    return (
      <span
        key={link}
        className={`menu-link ${active ? "active-menu-link" : ""}`}
        onClick={handleClick(link)}
      >
        {link}
      </span>
    );
  });

  return (
    <div className="menu-navigation-container menu-desktop-container">
      {menuLinks}
    </div>
  );
}

export default MenuNavigation;
