import React from "react";
import MenuJumbotron from "../Components/MenuComponents/MenuJumbotron";
import MenuContent from "../Components/MenuComponents/MenuContent";

import useScrollToTop from "../Utils/useScrollToTop";

function Menu() {
  useScrollToTop();
  return (
    <main>
      <MenuJumbotron />
      <h1 className="section-header menu-header">MENU</h1>
      <MenuContent />
    </main>
  );
}

export default Menu;
